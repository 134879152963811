import cx from "classnames"
import isFunction from "lodash/isFunction"
import { isValidElement, useCallback, useEffect, useMemo, useState } from "react"
import { rowFrontendType } from "src/constants/rowTypes"
import { treeMode } from "src/constants/tree"
import ErrorBoundary from "src/errors/ErrorBoundary"
import formatMessage from "src/helpers/formatMessageDisabled"
import { getTreeitemConfig } from "src/helpers/tree"
import useMouseLeave from "use-mouse-leave"
import DropdownOption from "../Dropdown/DropdownOption"
import DropdownTitle from "../Dropdown/DropdownTitle"
import { GanttRowDropdownIcon } from "../Gantt/GanttRow"
import Icon from "../Icon"
import Menu from "../Menu"
import Tooltip from "../Tooltip"
import TreeLabel from "../TreeLabel"
import TreeRowColumn from "./TreeRowColumn"
import styles from "./style.module.scss"

export const clickFrom = Object.freeze({
  arrow: 1,
  row: 2
})

export default function TreeRow({
  item,
  className = null,
  icon = null,
  label = null,
  labelSecondary = null,
  innerHtml = true,
  emphasis = false,
  isColorless = false,
  noBackground = false,
  toggleWithin = false,
  childrenContainerTitle = null,
  statuses = [],
  treeRowData,
  options = [],
  level = 0,
  open = false,
  active = false,
  canDrag = false,
  noContentPadding = false,
  labelIconColor = null,
  onlyWorksheets = false,
  dragActive = false,
  draggable = false,
  onClick = null,
  onDragStart = null,
  onDragEnd = null,
  onDragOver = null,
  onDragEnter = null,
  onDragLeave = null,
  onDrop = null,
  beforeDrop = null,
  propsSigningButtons,
  children = null,
  type = "",
  onlyLabel = false,
  typeTree = treeMode.AUDITPLAN,
  toggleButtonPosition = "RIGHT",
  messageNulled = "",
  borderToNewItem = false,
  displayType = "tree",
  readOnly = false,
  href = null,
  rowLabelClassName = null,
  truncateLabel = true,
  ...otherProps
}) {
  const [isDragOrigin, setIsDragOrigin] = useState(false)
  const [isDragTarget, setIsDragTarget] = useState(dragActive)
  const [isOpen, setIsOpen] = useState(open)
  const [isActive, setIsActive] = useState(active)
  const [isHovering, setIsHovering] = useState(false)
  const [mouseLeft, ref] = useMouseLeave()

  useEffect(() => {
    setIsOpen(open)
  }, [open])

  useEffect(() => {
    setIsActive(active)
  }, [active])

  useEffect(() => {
    setIsDragTarget(dragActive)
  }, [dragActive])

  useEffect(() => {
    if (mouseLeft) {
      setIsHovering(false)
    }
  }, [mouseLeft])

  const makeOnClick = useCallback(
    (from) => {
      if (typeof onClick === "undefined" || onClick === null || !isFunction(onClick)) {
        if (!children) {
          return null
        }

        return
      }

      return (event) => {
        event.preventDefault()
        event.stopPropagation()

        setIsActive(true)
        onClick({ isOpen, from })
      }
    },
    [onClick, children]
  )

  const makeOnDragStart = () => {
    if (readOnly) {
      return undefined
    }
    if (onDragStart === null) {
      return undefined
    }

    return (event) => {
      event.stopPropagation()

      setIsDragOrigin(true)

      onDragStart(event)
    }
  }

  const makeOnDragEnd = () => {
    if (onDragEnd === null) {
      return undefined
    }

    return (event) => {
      event.stopPropagation()

      setIsDragOrigin(false)
      onDragEnd()
    }
  }

  const makeOnDragOver = () => {
    if (onDragOver === null) {
      return (event) => {
        event.stopPropagation()
        event.preventDefault()
      }
    }

    return (event) => {
      event.stopPropagation()
      event.preventDefault()

      onDragOver(event)
    }
  }

  const makeOnDrop = () => {
    return (event) => {
      event.preventDefault()
      event.stopPropagation()

      if (onDrop) {
        if (!beforeDrop || beforeDrop(item)) {
          onDrop(event)
        }
      }
      setIsDragTarget(false)
      setIsDragOrigin(false)
    }
  }

  const makeOnDragEnter = () => {
    if (onDragEnter === null) {
      return undefined
    }

    return (event) => {
      event.stopPropagation()
      event.preventDefault()

      if (!beforeDrop || beforeDrop(item)) {
        setIsDragTarget(true)
      }
      onDragEnter()
    }
  }

  const makeOnDragLeave = () => {
    if (onDragLeave === null) {
      return undefined
    }

    return (event) => {
      event.stopPropagation()
      event.preventDefault()

      setIsDragTarget(false)
      onDragLeave()
    }
  }

  const onClickCallback = makeOnClick(clickFrom.row)

  const rowOptions = () => {
    const mappedOptions = options.map((option, idx) => {
      if (option == null) {
        return
      }

      if (typeof option.component !== "undefined") {
        if (isValidElement(option.component)) {
          return option.component
        } else {
          const Component = option.component
          return <Component item={option} key={`row-option-${idx}`} {...option.componentProps} />
        }
      }

      if (option.disabled_text) {
        option.disabled = true
      } else if (option.motive) {
        option.disabled_text = `${treeMode.EVALUATION !== typeTree ? "" : "La evaluación se encuentra "}${
          option.motive
        }`
      }

      const button = (
        <button
          ref={option.ref}
          type='button'
          onClick={(event) => {
            event.preventDefault()
            event.stopPropagation()

            if (option.disabled) {
              return
            }

            if (option.action) {
              option.action()

              return
            }
          }}
          onMouseEnter={(event) => {
            if (option.options.length > 0) {
              event.preventDefault()
              event.stopPropagation()

              if (option.action) {
                if ("disabled" in option && option.disabled) {
                  return
                }

                option.action()
              }
            }
          }}
          className={`${styles.treeOption}${
            "disabled" in option && option.disabled ? ` ${styles.treeOptionDisabled}` : ""
          }`}
        >
          {isValidElement(option.icon) ? (
            option.icon
          ) : typeof option.icon === "string" ? (
            <i className={`ui icon ${option.icon} `} />
          ) : (
            <Icon size={option.icon?.size ?? 24} name={option.icon?.name} color={option.icon?.color} />
          )}
        </button>
      )

      return (
        <div key={`tree-row-option-${idx}`}>
          {option.disabled || !!option.tooltip ? (
            option.showTooltip === false ? (
              <div>{button}</div>
            ) : (
              <Tooltip
                content={
                  <span>
                    {"disabled" in option && option.disabled && option.disabled_text
                      ? formatMessage({
                          type: "disabled",
                          button: option.tooltip || "",
                          motive: option.disabled_text
                        })
                      : option.tooltip}
                  </span>
                }
                style={{ maxWidth: 400 }}
              >
                {button}
              </Tooltip>
            )
          ) : (
            option.options?.length > 0 && (
              <Menu
                content={
                  <div style={{ margin: -10 }}>
                    <DropdownTitle value={option.dropdownTitle} />
                    {option.options.map((_option, _idx) =>
                      _option.href ? (
                        <DropdownOption
                          key={`tree-row-option-${idx}-${_idx}`}
                          value={_option.value}
                          href={_option.href}
                        />
                      ) : (
                        <DropdownOption
                          key={`tree-row-option-${idx}-${_idx}`}
                          value={_option.value}
                          action={(ev) => {
                            ev.stopPropagation()
                            _option.action()
                          }}
                        />
                      )
                    )}
                  </div>
                }
              >
                {button}
              </Menu>
            )
          )}
        </div>
      )
    })

    return <div className={cx(styles.treeOptionsWrapper, isHovering && styles.hover)}>{mappedOptions}</div>
  }

  const rowContent = () => {
    let statusLength
    let addColumn
    switch (type) {
      case rowFrontendType.PHASE:
      case rowFrontendType.BUSINESS_UNIT:
      case rowFrontendType.MACROPROCESS:
      case rowFrontendType.PROCESS:
      case rowFrontendType.SUB_PROCESS:
        statusLength = statuses.length + 1
        addColumn = <TreeRowColumn key='' columnData='' />
        break
      default:
        statusLength = statuses.length
        break
    }
    return (
      <>
        {toggleButtonPosition === "LEFT" && toggleWithin && toggleButton}
        <div
          className={cx(styles.side, styles.sideWithLabel, rowLabelClassName)}
          style={{
            paddingLeft: treeMode.EVALUATION !== typeTree ? 0 : toggleWithin && toggleButton ? 0 : 24,
            marginLeft: treeMode.EVALUATION === typeTree ? -8 : null
          }}
        >
          <TreeLabel
            iconSize={emphasis ? "big" : "small"}
            icon={icon}
            color={labelIconColor}
            name={label}
            innerHtml={innerHtml}
            isColorless={isColorless}
            showIcon={showIcon}
            href={href}
            truncate={truncateLabel}
          />
          {labelSecondary}
          {rowOptions()}
        </div>
        <div className={`${styles.side} ${styles.sideWithStatuses} ${styles[`statuses${statusLength}`]}`}>
          {statuses.map((el) => (
            <TreeRowColumn
              propsSigningButtons={propsSigningButtons}
              item={item}
              key={`tree-row-status-${el.statusId}`}
              onClick={el.onClick}
              columnData={el}
              width={el.width}
            />
          ))}

          {addColumn}

          {toggleButtonPosition === "RIGHT" && toggleWithin && toggleButton}
        </div>
      </>
    )
  }

  const handleOnMouseEnter = (event) => {
    event.preventDefault()

    setIsHovering(true)
  }

  const handleOnMouseLeave = (event) => {
    event.preventDefault()

    setIsHovering(false)
  }

  const hasChildrenClassName = children ? ` ${styles.hasChildren}` : ""
  const isOpenClassName = children && isOpen ? ` ${styles.isOpen}` : ""
  const isActiveClassName = isActive ? ` ${styles.isActive}` : ""
  const colorBorderClassName = borderToNewItem ? ` ${styles.colorBorderActive}` : ""
  const isDragOriginClassName = isDragOrigin ? ` ${styles.isDragOrigin}` : ""
  const dragTargetClassName = isDragTarget && !isDragOrigin ? ` ${styles.isDragTargetOK}` : ""

  const isDraggableClassName = draggable ? ` ${styles.isDraggable}` : ""

  const showIcon = getTreeitemConfig(item, "treeRowBarShowIcon", false)
  const borderPaddingRowClassName = showIcon ? styles.borderPaddingRow : null

  const extraClassName = typeof className !== "string" && className === "" ? "" : ` ${className}`
  const rowNoBackgroundClassName = noBackground ? ` ${styles.treeRowNoBackground}` : ""
  const rowClassName = cx(
    styles.treeRow,
    borderPaddingRowClassName,
    hasChildrenClassName,
    isActiveClassName,
    isOpenClassName,
    dragTargetClassName,
    isDragOriginClassName,
    rowNoBackgroundClassName,
    isDraggableClassName,
    extraClassName,
    colorBorderClassName
  )

  const noCollapseButtonClassName =
    displayType == "tree" && !children && !onlyLabel && !noBackground ? ` ${styles.noCollapseButton}` : ""
  const wrapperClassName = `${styles.treeRowWrapper}${noCollapseButtonClassName}`

  const toggleButton = useMemo(() => {
    if (!children) {
      return null
    }

    const isActiveCollapseButtonClassName = isOpen ? ` ${styles.collapseRowOpen}` : ""
    const collapseButtonClassName = `${styles.collapseRow} ${
      toggleButtonPosition === "LEFT" && styles.collapseRowLeft
    }${isActiveCollapseButtonClassName}`

    return (
      <button
        style={{ padding: treeMode.EVALUATION === typeTree ? 0 : null }}
        className={collapseButtonClassName}
        onClick={makeOnClick(clickFrom.arrow)}
      >
        <GanttRowDropdownIcon className={styles.treeRowArrow} size={21} />
      </button>
    )
  }, [children, isOpen, makeOnClick, typeTree])

  const rowFactory = () => {
    if (onClickCallback !== null) {
      const classNameBarRow = cx(styles.paddingBarRow, styles[type])

      return () => (
        <ErrorBoundary>
          <div className={wrapperClassName}>
            {!toggleWithin && toggleButton}

            <div
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
              onClick={onClickCallback}
              className={rowClassName}
              draggable={draggable}
              onDragStart={makeOnDragStart()}
              onDragEnd={makeOnDragEnd()}
              onDragOver={makeOnDragOver()}
              onDrop={makeOnDrop()}
              onDragEnter={makeOnDragEnter()}
              onDragLeave={makeOnDragLeave()}
              {...otherProps}
            >
              {showIcon ? null : <div className={classNameBarRow} style={getTreeitemConfig(item, "treeRowBarStyle")} />}
              {rowContent()}
            </div>
          </div>
          {messageNulled !== "" && <div style={{ color: "#ed4138", paddingLeft: 32 }}>{messageNulled}</div>}
        </ErrorBoundary>
      )
    }

    return () => (
      <ErrorBoundary>
        <div className={styles.treeRowWrapper}>
          {!toggleWithin && toggleButton}

          <div
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
            className={rowClassName}
            draggable={draggable}
            onDragStart={makeOnDragStart()}
            onDragEnd={makeOnDragEnd()}
            onDragOver={makeOnDragOver()}
            onDrop={makeOnDrop()}
            onDragEnter={makeOnDragEnter()}
            onDragLeave={makeOnDragLeave()}
            {...otherProps}
          >
            {rowContent()}
          </div>
        </div>
      </ErrorBoundary>
    )
  }

  const row = rowFactory()

  if (!children) return row()

  return (
    <div className={styles.rowWithChildren}>
      {row()}
      {isOpen ? (
        <div
          className={`${styles.rowChildren}${noContentPadding ? " " + styles.noContentPadding : ""}${
            childrenContainerTitle === null || typeof childrenContainerTitle === "undefined"
              ? ""
              : ` ${styles.childrenContained}`
          }`}
        >
          {typeof childrenContainerTitle === "string" ? (
            <div className={styles.childrenContainerTitle}>{childrenContainerTitle}</div>
          ) : null}
          {children}
        </div>
      ) : null}
    </div>
  )
}
